import i18n from '@/i18n'

export enum UserAccountType {
  individual = 'individual',
  company = 'company',
}

export const userAccountTypes = {
  [UserAccountType.individual]: i18n.t('Individual'),
  [UserAccountType.company]: i18n.t('Company'),
}

export enum UserNotificationType {
  sampleNotification = 'sample-notification',
}

export enum UserType {
  designer = 'designer',
  client = 'client',
}

export enum ResolutionType {
  postpone_request = 'postpone_request',
  cancel_request = 'cancel_request',
  modify_request = 'modify_request',
  ask_to_pay_request = 'ask_to_pay',
}

export enum UserProfileVisibility {
  public = 'public',
  private = 'private',
  users = 'users',
}

export enum UserProjectPreference {
  any = 'any',
  one_time = 'one_time',
  ongoing = 'ongoing',
}

export enum UserExperienceLevel {
  entry = 'entry',
  intermediate = 'intermediate',
  expert = 'expert',
}
