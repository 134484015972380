export default class RadioGroupOption {
  value: any = null!
  title: any = null!

  setValue(value: any): this {
    this.value = value

    return this
  }

  setTitle(title: any): this {
    this.title = title

    return this
  }
}
