import http from '@/shared/http'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import tokenHelper from '@/shared/helpers/token.helper'
import languageToken from '@/shared/helpers/language.helper'
import i18n from '@/i18n'
import store from '@/shared/store'
import { AuthMutations } from '@/shared/store/auth/auth.mutations'
import { ProjectMutations } from '@/shared/store/project/project.mutations'
import router, { Routes } from '@/shared/router'

http.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = tokenHelper.getToken()

  const langToken = languageToken.getToken()

  if (token) config.headers.Authorization = `Bearer ${token}`

  config.headers['Content-Type'] = 'application/json'

  if (langToken) {
    config.headers['X-localization'] = langToken

    return config
  }

  config.headers['X-localization'] = i18n.locale

  return config
})

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      store.commit(AuthMutations.reset)
      store.commit(ProjectMutations.clearProject)

      if (router.currentRoute.name !== Routes.home) router.push({ name: Routes.home })
    }
    throw error
  }
)
