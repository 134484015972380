export enum ModalEvents {
  hideModal = 'bv::hide::modal',
}

export enum ModalTypes {
  headerAuth = 'header-sign-up',
  homeAuth = 'home-modal',
  projectCreateAuth = 'project-create-auth',
  imageGallery = 'images-gallery',
  offerInvoice = 'offer-invoice',
  projectPreview = 'project-preview',
  resetProject = 'reset-project',
}
