import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import FormTemplate from '@/components/form/alternative/FormTemplate.vue'
import TextField from '@/components/form/alternative/TextField.vue'
import SliderField from '@/components/form/alternative/SliderField.vue'
import SelectField from '@/components/form/alternative/SelectField.vue'
import RichEditor from '@/components/form/alternative/RichEditor.vue'
import CoverField from '@/components/form/alternative/CoverField.vue'
import TagsField from '@/components/form/alternative/TagField.vue'
import CategoryPickerField from '@/components/form/alternative/CategoryPickerField.vue'
import RadioSelect from '@/components/form/alternative/RadioSelect.vue'
import TogglableTextField from '@/components/form/alternative/TogglableTextField.vue'
import CheckboxField from '@/components/form/alternative/CheckboxField.vue'
import MultipleSelectField from '@/components/form/alternative/MultipleSelectField.vue'
import PasswordField from '@/components/form/alternative/PasswordField.vue'
import SearchBar from '@/components/form/alternative/SearchBar.vue'
import ProjectCreateSection from '@/components/projects/ProjectCreateSection.vue'
import TextAreaField from '@/components/form/alternative/TextAreaField.vue'
import DatePopoverField from '@/components/form/alternative/DatePopoverField.vue'
import DateTimePopoverField from '@/components/form/alternative/DateTimePopoverField.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'
import VueStickyKit from 'vue-stickykit'
import VueTheMask from 'vue-the-mask'
import MaskField from '@/components/form/alternative/MaskField.vue'

export default {
  install(): void {
    Vue.config.productionTip = false

    Vue.use(VueClipboard)
    Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY || '' })
    Vue.use(VueStickyKit)
    // @ts-ignore
    Vue.use(VueTheMask)

    Vue.component('FormTemplate', FormTemplate)
    Vue.component('TextField', TextField)
    Vue.component('MaskField', MaskField)
    Vue.component('SliderField', SliderField)
    Vue.component('SelectField', SelectField)
    Vue.component('RichEditor', RichEditor)
    Vue.component('CoverField', CoverField)
    Vue.component('TagsField', TagsField)
    Vue.component('CategoryPickerField', CategoryPickerField)
    Vue.component('RadioSelect', RadioSelect)
    Vue.component('TogglableTextField', TogglableTextField)
    Vue.component('CheckboxField', CheckboxField)
    Vue.component('MultipleSelectField', MultipleSelectField)
    Vue.component('PasswordField', PasswordField)
    Vue.component('SearchBar', SearchBar)
    Vue.component('ProjectCreateSection', ProjectCreateSection)
    Vue.component('TextAreaField', TextAreaField)
    Vue.component('DatePopoverField', DatePopoverField)
    Vue.component('DateTimePopoverField', DateTimePopoverField)
    Vue.component('SearchBar', SearchBar)
    Vue.component('RangeSlider', RangeSlider)
  },
}
