import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import tokenHelper from '@/shared/helpers/token.helper'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import _ from 'lodash'
import User from '@/shared/interfaces/models/user/user'
import { UserType } from '@/shared/configs/user.config'
import i18n from '@/i18n'
import languageToken from '@/shared/helpers/language.helper'
import permissions from '../helpers/permissions.helper'
import can from '../helpers/can.helper'
import store from '../store'

Vue.use(VueRouter)

export const navigationEventBus = new Vue()

export enum Routes {
  home = 'home',
  pricing = 'pricing',
  howItWorks = 'how-it-works',
  designerList = 'designer.list',
  designerIndex = 'designer.index',
  designerSelect = 'designer.select',
  projectParticipate = 'project.participate',
  projectIndex = 'project.index',
  projectTab = 'project.tab',
  projectList = 'project.list',
  projectMy = 'project.my',
  projectPublished = 'project.published',
  proposal = 'project.proposal',
  proposalEdit = 'project.proposal.edit',
  projectCreate = 'project.create',
  projectCreateType = 'project.create.type',
  projectRedirect = 'project.redirect',
  projectContinue = 'project.continue',
  profileDesigner = 'designer.profile',
  taxRedirect = 'tax',
  profileDesignerAddProject = 'designer.profile.add-project',
  profileDesignerEditProject = 'designer.profile.edit-project',
  profile = 'profile',
  profileTax = 'profile.taxt',
  profilePayments = 'profile.payments',
  profilePayouts = 'profile.payouts',
  profileProjects = 'profile.projects',
  profileContracts = 'profile.contracts',
  profileInvoices = 'profile.invoices',
  stripeInvoices = 'profile.invoices.stripe',
  invoiceByProject = 'profile.invoices.project',
  profileSecurity = 'profile.security',
  profileSettings = 'profile.settings',
  profileMembership = 'profile.membership',
  profileNotifications = 'profile.notifications',
  profileAvailability = 'profile.availability',
  profileAboutMe = 'profile.about-me',
  emailVerify = 'email-verify',
  passwordReset = 'password-reset',
  notFound = 'notFound',
  serviceAgreement = 'documentation.service-agreement',
  privacyPolicy = 'documentation.privacy-policy',
  copyrightTerms = 'documentation.copyright-terms',
  confidentialityTerms = 'documentation.confidentiality-terms',
  paymentTerms = 'documentation.payment-terms',
  termsOfService = 'documentation.terms-of-service',
  socialGuidelines = 'documentation.social-guidelines',
  cookiePolicy = 'documentation.cookie-policy',
  authorMembership = 'documentation.author-membership',
  faq = 'documentation.faq',
  inspiration = 'inspiration',
  stripeCheckoutReturn = 'stripe-checkout-return',
  contact = 'contact',
  about = 'about',
  chat = 'chat',
  modifyProposal = 'project.modify-proposal',
  unsubscribe = 'unsubscribe',
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Routes.home,
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/pricing',
    name: Routes.pricing,
    component: () => import('@/views/Pricing.vue'),
  },
  {
    path: '/how-it-works',
    name: Routes.howItWorks,
    component: () => import('@/views/HowItWorks.vue'),
  },
  {
    path: '/designer/list',
    name: Routes.designerList,
    component: () => import('@/views/designers/Designers.vue'),
  },
  {
    path: '/designer/:id',
    name: Routes.designerIndex,
    component: () => import('@/views/designers/DesignerIndex.vue'),
  },
  {
    path: '/designer-select/:id',
    name: Routes.designerSelect,
    component: () => import('@/views/designers/DesignerIndex.vue'),
  },
  {
    path: '/profile/add-project',
    name: Routes.profileDesignerAddProject,
    component: () => import('@/views/profile/pages/AddProject.vue'),
    meta: {
      shouldLoggedIn: true,
    },
  },
  {
    path: '/profile/edit-project/:id',
    name: Routes.profileDesignerEditProject,
    component: () => import('@/views/profile/pages/EditProject.vue'),
    meta: {
      shouldLoggedIn: true,
      shouldBeDesigner: true,
    },
  },
  {
    path: '/profile',
    name: Routes.profileDesigner,
    component: () => import('@/views/profile/DesignerProfile.vue'),
    meta: {
      shouldLoggedIn: true,
    },
  },
  {
    path: '/tax',
    name: Routes.taxRedirect,
    component: () => import('@/components/profile/KycRedirect.vue'),
  },
  {
    path: '/settings',
    name: Routes.profile,
    component: () => import('@/views/profile/Profile.vue'),
    redirect: { name: Routes.profileNotifications },
    meta: {
      shouldLoggedIn: true,
    },
    children: [
      {
        path: 'tax',
        component: () => import('@/views/profile/pages/Tax.vue'),
        name: Routes.profileTax,
      },
      {
        path: 'payments',
        component: () => import('@/views/profile/pages/Payments.vue'),
        name: Routes.profilePayments,
      },
      {
        path: 'payouts',
        component: () => import('@/views/profile/pages/Payouts.vue'),
        name: Routes.profilePayouts,
      },
      {
        path: 'projects',
        component: () => import('@/views/profile/pages/Projects.vue'),
        name: Routes.profileProjects,
        meta: {
          shouldBeClient: true,
        },
      },
      {
        path: 'contracts',
        component: () => import('@/views/profile/pages/Contracts.vue'),
        name: Routes.profileContracts,
      },
      {
        path: 'invoices',
        component: () => import('@/views/profile/pages/Invoices.vue'),
        name: Routes.profileInvoices,
      },
      {
        path: 'stripe-invoices',
        component: () => import('@/views/profile/pages/StripeInvoices.vue'),
        name: Routes.stripeInvoices,
      },
      {
        path: 'invoices/:id',
        name: Routes.invoiceByProject,
        component: () => import('@/views/profile/pages/InvoicesByProject.vue'),
      },
      // {
      //   path: 'settings',
      //   component: () => import('@/views/profile/pages/Settings.vue'),
      //   name: Routes.profileSettings,
      // },
      {
        path: 'security',
        component: () => import('@/views/profile/pages/Security.vue'),
        name: Routes.profileSecurity,
      },
      {
        path: 'notifications',
        component: () => import('@/views/profile/pages/Notifications.vue'),
        name: Routes.profileNotifications,
      },
      {
        path: 'availability',
        component: () => import('@/views/profile/pages/Availability.vue'),
        name: Routes.profileAvailability,
      },
      {
        path: 'about-me',
        component: () => import('@/views/profile/pages/AboutMe.vue'),
        name: Routes.profileAboutMe,
      },
      {
        path: 'membership',
        component: () => import('@/views/profile/pages/Membership.vue'),
        name: Routes.profileMembership,
      },
    ],
  },
  {
    path: '/project/list',
    name: Routes.projectList,
    component: () => import('@/views/projects/list/ProjectList.vue'),
  },
  {
    path: '/project/my',
    name: Routes.projectMy,
    component: () => import('@/views/projects/list/MyProjects.vue'),
  },
  {
    path: '/project/create',
    name: Routes.projectCreate,
    component: () => import('@/views/projects/create/Main.vue'),
    meta: {
      shouldBeClient: true,
    },
  },
  {
    path: '/project/create/:type',
    name: Routes.projectCreateType,
    component: () => import('@/views/projects/create/Main.vue'),
  },
  {
    path: '/project/continue',
    name: Routes.projectContinue,
    component: () => import('@/views/ProjectContinue.vue'),
  },
  {
    path: '/project/:id',
    name: Routes.projectIndex,
    component: () => import('@/views/projects/index/pages/ProjectIndex.vue'),
    meta: {
      permissions: [permissions.projects.show],
    },
  },
  {
    path: '/project/:id/tab/:tab',
    name: Routes.projectTab,
    component: () => import('@/views/projects/index/pages/ProjectIndex.vue'),
    meta: {
      permissions: [permissions.projects.show],
    },
  },
  {
    path: '/project-redirect/:uuid',
    name: Routes.projectRedirect,
    component: () => import('@/views/ProjectRedirect.vue'),
  },
  {
    path: '/project/:id/participate',
    name: Routes.projectParticipate,
    component: () => import('@/views/projects/index/pages/Participate.vue'),
  },
  {
    path: '/project/:id/offer/:offerId/',
    name: Routes.proposal,
    component: () => import('@/views/projects/index/pages/ProjectPropose.vue'),
  },
  {
    path: '/project/:id/offer-edit/:offerId/',
    name: Routes.proposalEdit,
    component: () => import('@/views/projects/index/pages/ProjectPropose.vue'),
  },
  {
    path: '/project/:id/resolution/:resolutionId/modify',
    name: Routes.modifyProposal,
    component: () => import('@/views/projects/resolutions/ModifyProposal.vue'),
  },
  {
    path: '/project/:id/published',
    name: Routes.projectPublished,
    component: () => import('@/views/projects/index/pages/ProjectPublished.vue'),
    meta: {
      permissions: [permissions.projects.show, permissions.projects.submitStep],
    },
  },
  {
    path: '/not-found',
    name: Routes.notFound,
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '/email-verify',
    name: Routes.emailVerify,
    component: () => import('@/views/EmailVerify.vue'),
  },
  {
    path: '/password-reset',
    name: Routes.passwordReset,
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    path: '/terms-of-service/:id?',
    component: () => import('@/views/documentation/TermsOfService.vue'),
    name: Routes.termsOfService,
  },
  {
    path: '/social-guidelines/:id?',
    component: () => import('@/views/documentation/SocialGuidelines.vue'),
    name: Routes.socialGuidelines,
  },
  {
    path: '/service-agreement/:id?',
    component: () => import('@/views/documentation/ServiceAgreement.vue'),
    name: Routes.serviceAgreement,
  },
  {
    path: '/privacy-policy/:id?',
    component: () => import('@/views/documentation/PrivacyPolicy.vue'),
    name: Routes.privacyPolicy,
  },
  {
    path: '/copyright-terms/:id?',
    component: () => import('@/views/documentation/CopyrightTerms.vue'),
    name: Routes.copyrightTerms,
  },
  {
    path: '/confidentiality-terms/:id?',
    component: () => import('@/views/documentation/ConfidentialityTerms.vue'),
    name: Routes.confidentialityTerms,
  },
  {
    path: '/payment-terms/:id?',
    component: () => import('@/views/documentation/PaymentTerms.vue'),
    name: Routes.paymentTerms,
  },
  {
    path: '/cookie-policy/:id?',
    component: () => import('@/views/documentation/CookiePolicy.vue'),
    name: Routes.cookiePolicy,
  },
  {
    path: '/author-membership/:id?',
    component: () => import('@/views/documentation/AuthorMembership.vue'),
    name: Routes.authorMembership,
  },
  {
    path: '/faq/:id?',
    component: () => import('@/views/documentation/Faq.vue'),
    name: Routes.faq,
  },
  // {
  // path: '/inspiration',
  // component: () => import('@/views/Inspiration.vue'),
  // name: Routes.inspiration
  // },
  {
    path: '/stripe-checkout-return',
    component: () => import('@/views/StripeCheckoutReturn.vue'),
    name: Routes.stripeCheckoutReturn,
  },
  {
    path: '/contact-us',
    component: () => import('@/views/Contact.vue'),
    name: Routes.contact,
  },
  {
    path: '/about',
    component: () => import('@/views/About.vue'),
    name: Routes.about,
  },
  {
    path: '/unsubscribe',
    component: () => import('@/views/Unsubscribe.vue'),
    name: Routes.unsubscribe,
  },
  {
    path: '*',
    redirect: { name: Routes.notFound },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const has = (to: Route, what: string): boolean => to.matched.some((route: any) => _.get(route.meta, what))

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (typeof to.query?.subscriptions_cancel_url === 'string' && typeof to.query?.signature === 'string') {
    return next({
      name: Routes.unsubscribe,
      params: {
        subscriptions_cancel_url: `${to.query?.subscriptions_cancel_url}&signature=${to.query?.signature}`,
      },
    })
  }

  if (!languageToken.getToken()) {
    const userLanguage = navigator.language.split('-')[0].toLowerCase()
    switch (userLanguage) {
      case 'lt':
        i18n.locale = userLanguage
        languageToken.setToken(userLanguage)
        break
      default:
        i18n.locale = 'en'
        languageToken.setToken('en')
    }
  }

  if (
    (from.name === Routes.proposal || from.name === Routes.proposalEdit) &&
    to.params?.meta !== 'suppressNavigationWarning'
  ) {
    navigationEventBus.$emit('navigationWarning', to)

    return undefined
  }
  window.scrollTo(0, 0)

  const token: string | null = tokenHelper.getToken()
  let isLogged: boolean = store.getters[AuthGetters.isLogged]

  const user: User | null = store.getters[AuthGetters.getUser]

  if (token && !isLogged) {
    await store.dispatch(AuthActions.me)
    isLogged = store.getters[AuthGetters.isLogged]
  }

  if (to.meta) {
    if (has(to, 'permissions') && !can(to.meta.permissions)) {
      return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : next({ name: Routes.notFound })
    }

    if (has(to, 'shouldLoggedIn') && !isLogged) {
      return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : next({ name: Routes.notFound })
    }

    if (to.meta?.shouldBeClient && user?.role === UserType.designer) {
      return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : next({ name: Routes.notFound })
    }

    if (to.meta?.shouldBeDesigner && user?.role === UserType.client) {
      return to.meta.redirect ? next({ name: to.meta.redirect, params: to.params }) : next({ name: Routes.notFound })
    }
  }

  return next()
})

export default router
