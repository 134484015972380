export enum AuthEvents {
  auth = 'userAuthed',
  stage = 'changeStage',
}

export enum AuthTypes {
  login = 'login',
  register = 'register',
  anonymous = 'anonymous',
  forgot = 'password/send',
}
