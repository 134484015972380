import { AxiosResponse } from 'axios'
import PersonalInformation from '@/shared/interfaces/service/profile/personalInformation'
import AddressInformation from '@/shared/interfaces/service/profile/addressInformation'
import ChangePassword from '@/shared/interfaces/service/profile/changePassword'
import http from '../http'

export default class ProfileService {
  static async updateProfilePhoto(image: File): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('image', image)

    return http
      .post('user-details/upload-profile-picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse<AxiosResponse>) => response.data)
  }

  static async postPortfolio(data: FormData) {
    return http.post('portfolio-items', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static deletePortfolio(uuid: string): Promise<AxiosResponse> {
    return http.delete(`portfolio-items/${uuid}`)
  }

  static updatePortfolio(uuid: string, data: any): Promise<AxiosResponse> {
    return http.post(`portfolio-items/${uuid}/update`, data)
  }

  static async getProfilePortfolios(
    user_uuid: any,
    published: any,
    type: string = '',
    page: number = 1,
    perPage: number = 15
  ): Promise<AxiosResponse> {
    return http
      .get(
        `portfolio-items?user_uuid=${user_uuid}&published=${published}&filter-json-category=${type}&page=${page}&per_page=${perPage}`
      )
      .then((response: any) => response.data)
  }

  static async getPublicProfilePortfolios(
    user_uuid: any,
    published: any,
    type: string = '',
    page: number = 1,
    perPage: number = 15
  ): Promise<AxiosResponse> {
    return http
      .get(
        `portfolio-items/public?user_uuid=${user_uuid}&published=${published}&filter-json-category=${type}&page=${page}&per_page=${perPage}`
      )
      .then((response: any) => response.data)
  }

  static async getTaxData(): Promise<AxiosResponse> {
    return http.get('me/kyc-data').then((response: AxiosResponse) => response.data)
  }

  static async updateCodeAndVat(type: any, vat_code: any, code: any): Promise<AxiosResponse> {
    return http
      .put('update-personal-information', { type, vat_code, code })
      .then((response: AxiosResponse) => response.data)
  }

  static async updateProjectPreference(value: string): Promise<AxiosResponse> {
    return http
      .put('update-project-preference', { project_preference: value })
      .then((response: AxiosResponse) => response.data)
  }

  static async updateVisibility(value: string): Promise<AxiosResponse> {
    return http.put('update-visibility', { visibility: value }).then((response: AxiosResponse) => response.data)
  }

  static async updateExperienceLevel(value: string): Promise<AxiosResponse> {
    return http
      .put('update-experience-level', { experience_level: value })
      .then((response: AxiosResponse) => response.data)
  }

  static async disableAccount(): Promise<AxiosResponse> {
    return http.put('disable-account', {}).then((response: AxiosResponse) => response.data)
  }

  static async enableAccount(): Promise<AxiosResponse> {
    return http.put('enable-account', {}).then((response: AxiosResponse) => response.data)
  }

  static updatePersonalInformation({
    client_type,
    first_name,
    last_name,
    code,
    vat_code,
    phone,
  }: PersonalInformation): Promise<AxiosResponse> {
    return http
      .put('update-personal-information', { type: client_type, first_name, last_name, code, vat_code, phone })
      .then((response: AxiosResponse) => response.data)
  }

  static updateAddressInformation({ street_address, city, post_code, country }: AddressInformation) {
    return http.put('update-address-information', { street_address, city, post_code, country_uuid: country })
  }

  static async changeMembership(value: string): Promise<AxiosResponse> {
    return http
      .post('/users/change-membership-plan', { plan_uuid: value })
      .then((response: AxiosResponse) => response.data)
  }

  static getNotifications(): Promise<AxiosResponse> {
    return http.get('notifications/notifications-list')
  }

  static getNotificationsAmount(): Promise<AxiosResponse> {
    return http.get('notifications/notifications-amount')
  }

  static markNotificationAsRead(uuid: string): Promise<AxiosResponse> {
    return http.put(`notifications/${uuid}/mark-as-read`)
  }

  static markAllAsRead(): Promise<AxiosResponse> {
    return http.put('notifications/mark-all-as-read')
  }

  static getMessagesAmount(): Promise<AxiosResponse> {
    return http.get('chats/project-amount')
  }

  static getMessages(): Promise<AxiosResponse> {
    return http.get('chats/new-message-list')
  }

  static readMessage(uuid: string): Promise<AxiosResponse> {
    return http.put(`projects/${uuid}/mark-messages-as-read`)
  }

  static readAllMessages(): Promise<AxiosResponse> {
    return http.put('chats/mark-all-as-read')
  }

  static changePassword(data: ChangePassword) {
    return http.put('change-password', data)
  }

  static invoicedProjects(page: number) {
    return http.get(`invoiced-projects?per_page=12&page=${page}`)
  }

  static allInvoices(page: number) {
    return http.get(`users/invoices?per_page=12&page=${page}`)
  }

  static getInvoicesByProject(uuid: string, page: number) {
    return http.get(`projects/${uuid}/invoices?per_page=12&page=${page}`)
  }

  static membershipPaymentInfo() {
    return http.get('users/membership-payment-info')
  }

  static getStripeInvoices(page: number) {
    return http.get(`payment-invoices?page=${page}`)
  }
}
