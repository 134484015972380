var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field",class:_vm.size},[_c('div',{staticClass:"form-group",class:{
      'field--dark': _vm.readonly,
    }},[(_vm.title)?_c('label',{staticClass:"d-flex field-label"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('Editor',{attrs:{"api-key":"u7fub6llhs888mcjobeu7l10e6b2dsp7m7stzds18mu0ua7q","disabled":_vm.readonly,"init":{
        min_height: _vm.minHeight,
        max_height: _vm.maxHeight,
        contextmenu: _vm.contextMenu,
        menubar: _vm.menuBar,
        plugins: _vm.plugins,
        setup: _vm.makeSetup,
        file_picker_types: 'image media',
      }},on:{"input":_vm.emitChange},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.getError && _vm.getError.messages)?_c('b-form-invalid-feedback',{attrs:{"state":_vm.getErrorState}},_vm._l((_vm.getError.messages),function(message,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(message)+" ")])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }