import { ActionContext } from 'vuex'
import { AuthMutations } from '@/shared/store/auth/auth.mutations'
import http from '@/shared/http'
import tokenHelper from '@/shared/helpers/token.helper'
import User from '@/shared/interfaces/models/user/user'
import ResponseInterface from '@/shared/interfaces/response.interface'
import { AuthStateInterface } from '@/shared/store/auth/auth.state'
import router, { Routes } from '@/shared/router'
import { ProjectMutations } from '../project/project.mutations'

export enum AuthActions {
  me = 'AUTH/ME',
  logout = 'AUTH/LOGOUT',
}

export default {
  [AuthActions.me]: async ({ commit }: ActionContext<AuthStateInterface, any>) => {
    await http
      .get('me')
      .then((response: ResponseInterface<User>) => {
        commit(AuthMutations.setUser, response.data)
      })
      .catch(() => {
        tokenHelper.clearToken()
        commit(AuthMutations.reset)
        commit(ProjectMutations.clearProject)

        if (router.currentRoute.name !== Routes.home) router.push({ name: Routes.home })
      })
  },
  [AuthActions.logout]: ({ commit, state }: ActionContext<AuthStateInterface, any>) => {
    if (!state.user) return

    http
      .post('logout')
      .then(() => {
        commit(AuthMutations.reset)
        commit(ProjectMutations.clearProject)
      })
      .catch(() => {
        tokenHelper.clearToken()
        commit(AuthMutations.reset)
      })
      .finally(() => {
        if (router.currentRoute.name !== Routes.home) router.push({ name: Routes.home })
        localStorage.clear()
      })
  },
}
