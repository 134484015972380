import i18n from '@/i18n'

enum NotificationTypes {
  success = 'success',
  warning = 'warning',
  info = 'info',
  error = 'danger',
}

export const NotificationTypeData: any = {
  [NotificationTypes.error]: {
    icon: 'exclamation-circle-fill',
    variant: NotificationTypes.error,
    title: i18n.t('Error'),
    duration: 10,
  },
  [NotificationTypes.warning]: {
    icon: 'question-circle-fill',
    variant: NotificationTypes.warning,
    title: i18n.t('Warning'),
    duration: 5,
  },
  [NotificationTypes.info]: {
    icon: 'info-circle-fill',
    variant: NotificationTypes.info,
    title: i18n.t('Info'),
    duration: 3,
  },
  [NotificationTypes.success]: {
    icon: 'check-circle-fill',
    variant: NotificationTypes.success,
    title: i18n.t('Success'),
    duration: 3,
  },
}

export default NotificationTypes
