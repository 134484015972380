import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import _ from 'lodash'
import User from '../interfaces/models/user/user'
import Model from '../interfaces/model'
import hasDynamicPermissions from './dynamic-permissions.helper'

const can = (permissions: string[], atLeastOne = false, entry: Model | null = null): boolean => {
  if (!permissions) return true

  const user: User = store.getters[AuthGetters.getUser]

  if (entry && Object.prototype.hasOwnProperty.call(entry, 'dynamic_permissions'))
    return hasDynamicPermissions(permissions, entry, atLeastOne)

  if (!user) return false

  if (user.permissions['*']) return true

  const permissionsArray = permissions.map((permission: string) => _.get(user.permissions, permission))

  if (atLeastOne) return permissionsArray.some((e: boolean) => e === true)

  return permissionsArray.every((e: boolean) => e === true)
}

export default can
