import Model from '../interfaces/model'
import can from './can.helper'

const hasDynamicPermissions = (permissions: string[], entry: Model, atLeastOne = false): boolean => {
  if (!entry.dynamic_permissions) return true

  const hasPermission = (permission: string) => {
    const short = permission.split('.')[permission.split('.').length - 1]

    if (Object.prototype.hasOwnProperty.call(entry.dynamic_permissions, short))
      return !!entry.dynamic_permissions[short]

    return can([permission])
  }

  if (atLeastOne) return permissions.some(hasPermission)

  return permissions.every(hasPermission)
}

export default hasDynamicPermissions
