import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
  return {
    en: require('./translations/en.json'),
    lt: require('./translations/lt.json'),
  }
}

export default new VueI18n({
  locale: 'lt',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: process.env.NODE_ENV === 'test',
})
