import { ProjectStateInterface } from '@/shared/store/project/project.state'

export enum ProjectMutations {
  updateProject = 'PROJECT/UPDATE_PROJECT',
  clearProject = 'PROJECT/CLEAR_PROJECT',
  setProjectId = 'PROJECT/SET_ID',
  setPlan = 'PROJECT/SET_PLAN',
  setSelectedPlan = 'PROJECT/SET_SELECTED_PLAN',
  setPlanExtras = 'PROJECT/SET_PLAN_EXTRAS',
  setInvitedDesigner = 'PROJECT/SET_INVITED_DESIGNER',
  removeInvitedDesigner = 'PROJECT/REMOVE_INVITED_DESIGNER',
}

export default {
  [ProjectMutations.updateProject]: (state: ProjectStateInterface, payload: any): void => {
    state.project = { ...payload.data }
    state.progress = payload.progress
  },
  [ProjectMutations.clearProject]: (state: ProjectStateInterface): void => {
    state.project = null
    state.progress = null
    state.plan = null
    state.selectedPlan = null
    state.planExtras = null
    state.projectId = null
    state.invitedDesigner = null
  },
  [ProjectMutations.setProjectId]: (state: ProjectStateInterface, payload: any): void => {
    state.projectId = payload
  },
  [ProjectMutations.setSelectedPlan]: (state: ProjectStateInterface, payload: any): void => {
    state.selectedPlan = payload
  },
  [ProjectMutations.setPlan]: (state: ProjectStateInterface, payload: any): void => {
    state.plan = payload
  },
  [ProjectMutations.setPlanExtras]: (state: ProjectStateInterface, payload: any): void => {
    state.planExtras = payload
  },
  [ProjectMutations.setInvitedDesigner]: (state: ProjectStateInterface, payload: any): void => {
    state.invitedDesigner = payload
  },
  [ProjectMutations.removeInvitedDesigner]: (state: ProjectStateInterface): void => {
    state.invitedDesigner = null
  },
}
