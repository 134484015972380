import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/shared/store/auth/auth.index'
import project from '@/shared/store/project/project.index'
import currency from '@/shared/store/currency/currency.index'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  reducer: (state: any) => ({ project: state.project }),
})

export default new Vuex.Store({
  modules: {
    auth,
    project,
    currency,
  },
  plugins: [vuexLocal.plugin],
})
