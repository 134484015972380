import { FieldSize, FieldType } from '@/shared/configs/field.config'
import SelectOption from '@/shared/classes/form/select-option'
import RadioGroupOption from '@/shared/classes/form/radio-group-option'
import SimpleFieldSkeleton from '@/components/skeleton/SimpleFieldSkeleton.vue'

export interface MetaInterface {
  multiple?: boolean
  array?: {
    component?: any
    maxSelected?: number
  }
}

export interface PasswordInterface {
  minLength?: number
  passwordVisibility?: boolean
  showMeter?: boolean
}

export interface FixedTime {
  hours: number
  minutes: number
}

export default class Field {
  type: FieldType = FieldType.text
  key: string = null!
  entryKey: string = null!
  title: string | ((data: any) => string) = null!
  placeholder: string = null!
  value!: any
  disabled = false
  section: string = null!
  selectOptions: SelectOption[] = []
  radioGroupOptions: RadioGroupOption[] = []
  arrayValues: any[] = []
  footerComponent: any = null!
  passedData: (data: any) => any = () => null
  optional = false
  meta!: MetaInterface
  visibleIf: (data: any) => boolean = () => true
  onMatch: (data: any) => boolean = () => false
  disabledIf: (data: any) => boolean = () => false
  component!: any
  options!: any
  fields!: Field[]
  defaultValue!: any
  allowedFields!: any
  size: FieldSize | ((data: any) => FieldSize) = FieldSize.full
  required = false
  minValue!: number
  radioNewLine!: boolean
  newLine = false
  passwordSettings: PasswordInterface = {
    minLength: 0,
    passwordVisibility: true,
    showMeter: false,
  }
  extraClass!: string
  skeletonLoader: any = SimpleFieldSkeleton
  fileTypes: string[] = []
  maxFileSize: number | null = null
  uploadedFiles: File[] = []
  instantValidation!: boolean
  fixedTime!: FixedTime
  maxCharacters!: number
  maxNumber!: number

  setType(type: FieldType): this {
    this.type = type

    return this
  }

  setKey(key: string): this {
    this.key = key

    return this
  }

  setFileTypes(fileTypes: string[]): this {
    this.fileTypes = fileTypes

    return this
  }

  setMaxFileSize(number: number): this {
    this.maxFileSize = number

    return this
  }

  setNewLine(newLine: boolean): this {
    this.newLine = newLine

    return this
  }

  setFields(field: Field[]): this {
    this.fields = field

    return this
  }

  setTitle(title: string | ((data: any) => string)): this {
    this.title = title

    return this
  }

  setPlaceholder(placeholder: string): this {
    this.placeholder = placeholder

    return this
  }

  setClass(extraClass: string): this {
    this.extraClass = extraClass

    return this
  }

  setValue(value: any): this {
    this.value = value

    return this
  }

  setRequired(required: boolean): this {
    this.required = required

    return this
  }

  setMinValue(value: number): this {
    this.minValue = value

    return this
  }

  setDisabled(disabled: boolean): this {
    this.disabled = disabled

    return this
  }

  setSection(section: string): this {
    this.section = section

    return this
  }

  setSelectOptions(selectOptions: SelectOption[]): this {
    this.selectOptions = selectOptions

    return this
  }

  setRadioGroupOptions(radioGroupOptions: RadioGroupOption[]): this {
    this.radioGroupOptions = radioGroupOptions

    return this
  }

  setArrayValues(arrayValues: any[]): this {
    this.arrayValues = arrayValues

    return this
  }

  setFooterComponent(footerComponent: any): this {
    this.footerComponent = footerComponent

    return this
  }

  setOptional(optional: boolean): this {
    this.optional = optional

    return this
  }

  setPassedData(passedData: (data: any) => any): this {
    this.passedData = passedData

    return this
  }

  setMeta(meta: MetaInterface): this {
    this.meta = meta

    return this
  }

  setComponent(component: any): this {
    this.component = component

    return this
  }

  setOptions(options: any): this {
    this.options = options

    return this
  }

  setVisibleIf(visibleIf: (data: any) => boolean): this {
    this.visibleIf = visibleIf

    return this
  }

  setOnMatch(onMatch: (data: any) => boolean): this {
    this.onMatch = onMatch

    return this
  }

  setDisabledIf(disabledIf: (data: any) => boolean): this {
    this.disabledIf = disabledIf

    return this
  }

  setDefaultValue(value: any): this {
    this.defaultValue = value

    return this
  }

  setAllowedFields(allowedFields: any): this {
    this.allowedFields = allowedFields

    return this
  }

  setSize(size: FieldSize | ((data: any) => FieldSize)): this {
    this.size = size

    return this
  }

  isRequired(): this {
    this.required = true

    return this
  }

  setRadioNewLine(radioNewLine: boolean): this {
    this.radioNewLine = radioNewLine

    return this
  }

  setPasswordSettings(passwordSettings: PasswordInterface): this {
    this.passwordSettings = passwordSettings

    return this
  }

  setUploadedFiles(files: File[]): this {
    this.uploadedFiles = files

    return this
  }

  setFixedTime(fixedTime: FixedTime): this {
    this.fixedTime = fixedTime

    return this
  }

  setMaxCharacters(maxCharacters: number): this {
    this.maxCharacters = maxCharacters

    return this
  }

  setMaxNumber(maxNumber: number): this {
    this.maxNumber = maxNumber

    return this
  }

  validation(setting: boolean): this {
    this.instantValidation = setting

    return this
  }
}
