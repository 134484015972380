import Field from '@/shared/classes/form/field'
import HttpMethod from '@/shared/configs/http-method.config'
import { AxiosError } from 'axios'
import FormSection from '@/shared/classes/form/form-section'
import http from '@/shared/http'
import _ from 'lodash'
import i18n from '@/i18n'

interface SubmitInterface {
  text: string
  loadingText: string
  classes?: string
}

export default class FormBase {
  uuid: string | number = null!
  method: HttpMethod = HttpMethod.POST
  endpoint: string = null!
  sections: FormSection[] = null!
  fields: Field[] = []
  data: any = {}
  entry: any = null
  initialValues: any = null
  errors: any = {}
  submit: SubmitInterface | boolean = {
    text: String(i18n.t('Submit')),
    loadingText: String(i18n.t('Submitting...')),
    classes: 'd-flex justify-content-start align-items-center',
  }
  onSuccess: (data: any) => void = null!
  onChange: () => void = null!
  loading = false
  changeDataBeforeSubmit!: (data: any) => void

  setUuid(uuid: string | number): this {
    this.uuid = uuid

    return this
  }

  setMethod(method: HttpMethod): this {
    this.method = method

    return this
  }

  setEndpoint(endpoint: string): this {
    this.endpoint = endpoint

    return this
  }

  setSections(sections: FormSection[]): this {
    this.sections = sections

    return this
  }

  setFields(field: Field[]): this {
    this.fields = field

    return this
  }

  setData(data: any): this {
    this.data = data

    return this
  }

  setEntry(entry: any): this {
    this.entry = entry

    return this
  }

  setInitialValues(initialValues: any): this {
    this.initialValues = initialValues

    return this
  }

  setErrors(errors: any): this {
    this.errors = errors

    return this
  }

  setSubmit(submit: SubmitInterface | boolean): this {
    this.submit = submit

    return this
  }

  setOnSuccess(onSuccess: (data: any) => void): this {
    this.onSuccess = onSuccess

    return this
  }

  setOnChange(onChange: () => void): this {
    this.onChange = onChange

    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading

    return this
  }

  public catchErrors(error: AxiosError<any>): void {
    if (!error || !error.response) return

    if (error.response.status === 422) {
      error.response.data.errors && this.parseErrors(error.response.data.errors)
    }
  }

  private parseErrors(errors: any): void {
    const formErrors: any = {}

    Object.keys(errors).forEach((key: string) => {
      formErrors[key] = {
        has: true,
        count: errors[key].length,
        messages: errors[key],
      }
    })

    this.setErrors(formErrors)
  }

  setChangeDataBeforeSubmit(changeDataBeforeSubmit: (data: any) => any): this {
    this.changeDataBeforeSubmit = changeDataBeforeSubmit

    return this
  }

  async submitForm() {
    const uuid = this.uuid || ''

    const { onSuccess } = this

    const { method } = this
    let { endpoint } = this
    let data = this.prepareData()
    data = this.changeDataBeforeSubmit ? this.changeDataBeforeSubmit(data) : data

    const call: any = http[method]

    this.setLoading(true)

    if (!endpoint) {
      this.setLoading(false)

      return onSuccess && onSuccess(data)
    }

    endpoint = uuid ? `${endpoint}/${uuid}` : endpoint

    const response = await call(endpoint, data)
      .then(async (response: any) => {
        this.setErrors({})
        onSuccess && (await onSuccess(response.data))

        return response
      })
      .catch((error: any) => {
        this.catchErrors(error)
      })
      .finally(() => this.setLoading(false))

    return response
  }

  prepareData(): any {
    const data = _.cloneDeep(this.data)

    return data
  }
}
