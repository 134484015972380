import Vue from 'vue'
import App from '@/App.vue'
import store from '@/shared/store'
import router from '@/shared/router'
import '@/shared/plugins/bootstrap' // Bootstrap configuration
import '@/shared/plugins/calendar'
import '@/shared/plugins/vue-drawing-canvas'
import '@/assets/scss/app.scss'
import setup from '@/setup'
import AOS from 'aos'
import i18n from '@/i18n'
import * as Sentry from '@sentry/vue'

Vue.use(setup)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN || '',
  environment: process.env.NODE_ENV,
})

require('@/shared/plugins/setup-interceptors')

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    AOS.init({ once: true })
  },
}).$mount('#app')
