import _ from 'lodash'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import http from '@/shared/http'
import { FieldType } from '@/shared/configs/field.config'

const setDefaultFieldValue = (
  field: any,
  form: FormBase | null = null,
  entry: any = null,
  initialValues: any = null
) => {
  let value: any = null

  initialValues = initialValues || form?.initialValues

  if (form) value = _.get(initialValues, field.key, null)

  if (entry) value = _.get(entry, field.key, null)

  switch (field.type) {
    case FieldType.array:
      if (value === null) value = []
      break
    default:
      if (value === null) value = ''
      break
  }

  if (field.options && field.options.undefined && !value) value = undefined

  if (field.options && field.options.array && !value) value = []

  return value
}

const presetDefaultValues = (form: FormBase): any => {
  const data = {}

  form.fields.forEach((field: Field) => {
    const value = setDefaultFieldValue(field, form)

    _.set(data, field.key, value)
  })

  return data
}

const parseEntryValues = (form: any, entry: any = null): void => {
  const data: any = presetDefaultValues(form)

  form.fields.forEach((field: Field) => {
    if (!_.has(entry, field.key)) return

    data[field.key] = entry[field.key]
  })

  form.setData(data)
}

const loadEntryAndPresetValues = async (form: FormBase) => {
  const { endpoint, uuid } = form
  form.setLoading(true)

  if (!endpoint) throw Error('Form endpoint not set, please check code and try again')

  await http
    .get(`${endpoint}/${uuid}`)
    .then((response: any) => {
      const entry = response.data
      form.setEntry(entry)

      parseEntryValues(form, entry)
    })
    .finally(() => {
      form.setLoading(false)
    })
}

const presetInitialFormValues = async (form: FormBase) => {
  if (form.uuid) {
    await loadEntryAndPresetValues(form)

    return
  }

  form.setData(presetDefaultValues(form))
}

export default presetInitialFormValues
