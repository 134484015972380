export enum FieldType {
  text = 'text',
  number = 'number',
  email = 'email',
  password = 'password',
  textArea = 'textArea',
  select = 'select',
  array = 'array',
  file = 'file',
  radioGroup = 'radioGroup',
  date = 'date',
  custom = 'custom',
  selection = 'selection',
  tags = 'tags',
  promo = 'promo',
  multiSelect = 'multiSelect',
}

export const fieldTypeMap = {
  [FieldType.text]: () => import('@/components/form/fields/TextField.vue'),
  [FieldType.number]: () => import('@/components/form/fields/NumberField.vue'),
  [FieldType.email]: () => import('@/components/form/fields/EmailField.vue'),
  [FieldType.password]: () => import('@/components/form/fields/PasswordField.vue'),
  [FieldType.textArea]: () => import('@/components/form/fields/TextAreaField.vue'),
  [FieldType.select]: () => import('@/components/form/fields/SelectField.vue'),
  [FieldType.array]: () => import('@/components/form/fields/ArrayField.vue'),
  [FieldType.file]: () => import('@/components/form/fields/FileField.vue'),
  [FieldType.radioGroup]: () => import('@/components/form/fields/RadioGroupField.vue'),
  [FieldType.date]: () => import('@/components/form/fields/DateField.vue'),
  [FieldType.custom]: () => import('@/components/form/fields/CustomField.vue'),
  [FieldType.selection]: () => import('@/components/form/fields/custom/ExtraSelectionField.vue'),
  [FieldType.tags]: () => import('@/components/form/fields/TagsField.vue'),
  [FieldType.promo]: () => import('@/components/form/fields/custom/PromoField.vue'),
  [FieldType.multiSelect]: () => import('@/components/form/fields/MultiSelectField.vue'),
}

export enum FieldSize {
  full = '100%',
  half = '50%',
}
