import { AxiosResponse } from 'axios'
import http from '../http'

export default class AuthService {
  static async register(data: any): Promise<AxiosResponse> {
    return http.post('register', data)
  }

  static async registerNonVerified(data: any): Promise<AxiosResponse> {
    return http.post('register-not-verified', data)
  }

  static async verifyEmail(data: any): Promise<AxiosResponse> {
    return http.post('email/verify/code', data)
  }

  static async emailVerify(url: string): Promise<AxiosResponse> {
    return http.get(url)
  }

  static async resetPassword(data: any): Promise<AxiosResponse> {
    return http.post('password/reset', data)
  }

  static async sendResetPasswordLink(email: string): Promise<AxiosResponse> {
    return http.post('password/send', email)
  }

  static async checkResetToken(data: any): Promise<AxiosResponse> {
    return http.post('password/reset-check', data)
  }

  static async submitAnonymousUser(data: any): Promise<AxiosResponse> {
    return http.post('projects/anonymous', data).then((response: any) => response)
  }

  static async login(data: any): Promise<AxiosResponse> {
    return http.post('first-factor-login', data)
  }

  static async secondFactorLogin(data: any): Promise<AxiosResponse> {
    return http.post('second-factor-login', data)
  }

  static async generateCode(): Promise<AxiosResponse> {
    return http.post('user-codes/generate')
  }

  static async updateFaSettings(data: any): Promise<AxiosResponse> {
    return http.put('update-fa', data)
  }

  static async dismissWelcomeModal(): Promise<AxiosResponse> {
    return http.post('accept-welcome-page')
  }
}
