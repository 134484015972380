import { ProjectStateInterface } from '@/shared/store/project/project.state'

export enum ProjectGetters {
  getProject = 'PROJECT/GET_PROJECT',
  getProgress = 'PROGRESS/GET_PROGRESS',
  getProjectId = 'PROJECT/GET_ID',
  getPlan = 'PROJECT/GET_PLAN',
  getSelectedPlan = 'PROJECT/GET_SELECTED_PLAN',
  getPlanExtras = 'PROJECT/GET_PLAN_EXTRAS',
  getInvitedDesigner = 'PROJECT/GET_INVITED_DESIGNER',
}

export default {
  [ProjectGetters.getProject]: (state: ProjectStateInterface): any => state.project,
  [ProjectGetters.getProgress]: (state: ProjectStateInterface): any => state.progress,
  [ProjectGetters.getProjectId]: (state: ProjectStateInterface): any => state.projectId,
  [ProjectGetters.getPlan]: (state: ProjectStateInterface): any => state.plan,
  [ProjectGetters.getSelectedPlan]: (state: ProjectStateInterface): any => state.selectedPlan,
  [ProjectGetters.getPlanExtras]: (state: ProjectStateInterface): any => state.planExtras,
  [ProjectGetters.getInvitedDesigner]: (state: ProjectStateInterface): any => state.invitedDesigner,
}
