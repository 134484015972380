const permissions = {
  contracts: {
    sign: 'contracts.sign',
  },
  phases: {
    nextPhase: 'phases.nextPhase',
    requestRevision: 'phases.requestRevision',
    show: 'phases.show',
    submitRequiredMaterials: 'phases.submitRequiredMaterials',
  },
  portfolio: {
    addComment: 'portfolio-items.addComment',
    addLike: 'portfolio-items.addLike',
    removeComment: 'portfolio-items.removeComment',
    removeLike: 'portfolio-items.removeLike',
  },
  events: {
    index: 'project-events.index',
    show: 'project-events.show',
  },
  projects: {
    participate: 'projects.participate',
    additionalFiles: 'projects.additionalFiles',
    checkoutTotals: 'projects.checkoutTotals',
    deleteAdditionalFiles: 'projects.deleteAdditionalFiles',
    files: 'projects.files',
    index: 'projects.index',
    medias: 'projects.medias',
    myProjects: 'projects.myProjects',
    show: 'projects.show',
    store: 'projects.store',
    submitStep: 'projects.submitStep',
    submitStepFiles: 'projects.submitStepFiles',
    leave: 'projects.leave',
    offer: 'projects.offer',
    showInner: 'projects.showInner',
    propose: 'projects.propose',
    signCta: 'projects.signCta',
    viewCta: 'projects.viewCta',
    submitResolution: 'projects.submitResolution',
    cancelProject: 'projects.cancelProject',
  },
  promos: {
    check: 'promos.check',
  },
  users: {
    followingDesigners: 'users.following-designers',
    designers: 'users.designers',
  },
  resolutions: {
    accept: 'resolutions.accept',
    decline: 'resolutions.decline',
    cancel: 'resolutions.cancel',
    submitChanges: 'resolutions.submitChanges',
  },
}

export default permissions
