import LanguageData from '@/shared/interfaces/language-data'

export const languageData: LanguageData[] = [
  {
    locale: 'lt',
    name: 'Lietuvių',
  },
  {
    locale: 'en',
    name: 'English',
  },
]
