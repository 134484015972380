import ProgressBase from '@/shared/classes/progress/progress'
import { PlanData } from '@/shared/interfaces/models/plan'
import Project from '@/shared/interfaces/models/project/project'

export interface ProjectStateInterface {
  project: Project | null
  progress: ProgressBase | null
  projectId: string | null
  plan: PlanData | null
  selectedPlan: PlanData | null
  planExtras: any | null
  invitedDesigner: string | null
}

export default (): ProjectStateInterface => ({
  project: null,
  progress: null,
  projectId: null,
  plan: null,
  selectedPlan: null,
  planExtras: null,
  invitedDesigner: null,
})
