const authKey = 'languageToken'

const getToken = (): string | null => localStorage.getItem(authKey)

const setToken = (token: string) => localStorage.setItem(authKey, token)

const clearToken = (): void => localStorage.removeItem(authKey)

const languageToken = {
  getToken,
  setToken,
  clearToken,
}

export default languageToken
