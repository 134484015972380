import { AxiosResponse } from 'axios'
import Phase from '@/shared/interfaces/proposal/phase'
import DeclineMilestone from '@/shared/interfaces/proposal/DeclineMilestone'
import { PhaseFileData, PhaseFileEditPayload } from '@/shared/interfaces/proposal/phase-file'
import { ProjectAnonymousPayload } from '@/shared/interfaces/project/project-anonymous-payload'
import http from '../http'
import Project from '../interfaces/models/project/project'

export default class ProjectService {
  static updateProject(data: any, uuid: string): Promise<AxiosResponse> {
    return http.put(`projects/${uuid}`, data)
  }

  static getProject(uuid: string): Promise<Project> {
    return http.get(`projects/${uuid}`).then((response: AxiosResponse) => response.data)
  }

  static getProjectTypeList(): Promise<Project> {
    return http.get('project-types?per_page=999').then((response: AxiosResponse) => response.data)
  }

  static getProjectType(uuid: string): Promise<Project> {
    return http.get(`project-types/${uuid}`).then((response: AxiosResponse) => response.data)
  }

  static guestProjectInfo(uuid: string): Promise<AxiosResponse> {
    return http.get(`projects/${uuid}/project-show-guest`).then((response: any) => response.data)
  }

  static subscribeEmail(email: string): Promise<AxiosResponse> {
    return http.post('subscriptions', { email })
  }

  static generatePropose(uuid: string): Promise<AxiosResponse> {
    return http.get(`projects/${uuid}/generate-proposal`).then((response: any) => response.data)
  }

  static addEmptyPhase(offerId: string, index: number): Promise<AxiosResponse> {
    return http.post(`offers/${offerId}/add-phase`, { index }).then((response: any) => response.data)
  }

  static updatePhase(offerId: string, uuid: string, phase: Phase) {
    return http.post(`offers/${offerId}/add-phase/${uuid}`, phase).then((response: any) => response.data)
  }

  static startUpdate(offerId: string) {
    return http.get(`offers/${offerId}/edit-proposal`).then((response: any) => response.data)
  }

  static deletePhase(offerId: string, index: string): Promise<AxiosResponse> {
    return http.delete(`offers/${offerId}/delete-phase/${index}`)
  }

  static reviewProposal(offerId: string, uuid: string, phase: Phase) {
    return http.post(`offers/${offerId}/review-proposal/${uuid}`, phase).then((response: any) => response.data)
  }

  static getOffer(offerId: string): Promise<AxiosResponse> {
    return http.get(`offers/${offerId}`).then((response: any) => response.data)
  }

  static submitProposal(offerId: string, phases: any, valid_until: string, cover_letter: string) {
    return http
      .post(`offers/${offerId}/submit-proposal`, { valid_until, phases, cover_letter })
      .then((response: any) => response.data)
  }

  static submitUpdatedProposal(offerId: string, phases: any, valid_until: string, cover_letter: string) {
    return http
      .post(`offers/${offerId}/submit-changed-proposal`, { valid_until, phases, cover_letter })
      .then((response: any) => response.data)
  }

  static selectOffer(projectId: string, offerId: string) {
    return http
      .post(`projects/${projectId}/select-offer/${offerId}`, { offerId })
      .then((response: any) => response.data)
  }

  static declineOffer(projectId: string, offerId: string) {
    return http.post(`projects/${projectId}/decline-offer/${offerId}`).then((response: any) => response.data)
  }

  static submitResolution(projectId: any, data: any): Promise<AxiosResponse> {
    return http.post(`projects/${projectId}/submit-resolution`, data)
  }

  static checkOut(uuid: string): Promise<AxiosResponse> {
    return http.post(`projects/${uuid}/checkout-totals`).then((response: AxiosResponse) => response.data)
  }

  static cancel(uuid: string): Promise<AxiosResponse> {
    return http.get(`projects/${uuid}/cancel-project`).then((response: AxiosResponse) => response.data)
  }

  static signCta(uuid: string, data: any): Promise<AxiosResponse> {
    return http.post(`projects/${uuid}/sign-cta`, data).then((response: AxiosResponse) => response.data)
  }

  static submitPhase(uuid: string): Promise<AxiosResponse> {
    return http.put(`phases/${uuid}/upload-design-complete`).then((response: AxiosResponse) => response.data)
  }

  static acceptMilestone(uuid: string): Promise<AxiosResponse> {
    return http.post(`phases/${uuid}/end-phase`).then((response: AxiosResponse) => response.data)
  }

  static declineMilestone(uuid: string, data: DeclineMilestone): Promise<AxiosResponse> {
    return http.put(`phases/${uuid}/cancel-phase`, data).then((response: AxiosResponse) => response.data)
  }

  static uploadDocuments(uuid: string, documents: FormData): Promise<AxiosResponse> {
    return http
      .post(`phases/${uuid}/upload-designs`, documents, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse) => response.data)
  }

  static getBase64(phaseId: string, fileId: number): Promise<PhaseFileData> {
    return http.get(`phases/${phaseId}/open-uploaded-design/${fileId}`).then((response: AxiosResponse) => response.data)
  }

  static uploadEditedDesign(uuid: string, data: PhaseFileEditPayload): Promise<AxiosResponse> {
    return http.post(`phases/${uuid}/upload-edited-design`, data).then((response: AxiosResponse) => response.data)
  }

  static uploadFiles(uuid: string, files: FormData): Promise<AxiosResponse> {
    return http
      .post(`projects/${uuid}/upload-files`, files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse) => response.data)
  }

  static getFiles(uuid: string, user: string, phase: string): Promise<AxiosResponse> {
    return http
      .get(`projects/${uuid}/media-files?filter-user=${user}&filter-phase_uuid=${phase}`)
      .then((response: AxiosResponse) => response.data)
  }

  static getMedia(uuid: string): Promise<AxiosResponse> {
    return http.get(`projects/${uuid}/additional-files`).then((response: AxiosResponse) => response.data)
  }

  static deleteMediaFile(projectUuid: string, fileUuid: string): Promise<AxiosResponse> {
    return http
      .delete(`projects/${projectUuid}/media-files/${fileUuid}`)
      .then((response: AxiosResponse) => response.data)
  }

  static acceptResolution(uuid: string): Promise<AxiosResponse> {
    return http.post(`resolutions/${uuid}/accept`).then((response: AxiosResponse) => response.data)
  }

  static cancelResolution(uuid: string): Promise<AxiosResponse> {
    return http.post(`resolutions/${uuid}/cancel`).then((response: AxiosResponse) => response.data)
  }

  static declineResolution(uuid: string): Promise<AxiosResponse> {
    return http.post(`resolutions/${uuid}/decline`).then((response: AxiosResponse) => response.data)
  }

  static withdrawOffer(uuid: string): Promise<AxiosResponse> {
    return http.post(`projects/${uuid}/cancel-offer`).then((response: AxiosResponse) => response.data)
  }

  static removeAdditionalFile(projectUuid: string, mediaUUid: string): Promise<AxiosResponse> {
    return http.delete(`projects/${projectUuid}/media/${mediaUUid}/additional-file`)
  }

  static getInvoice(uuid: string): Promise<AxiosResponse> {
    return http.get(`users/invoice/${uuid}`).then((response: AxiosResponse) => response.data)
  }

  static startAnonymousProject(data: ProjectAnonymousPayload): Promise<AxiosResponse> {
    return http.post('projects/anonymous', data)
  }

  static recoverLatestDraft(): Promise<AxiosResponse> {
    return http.get('projects/latest-draft').then((response: AxiosResponse) => response.data)
  }

  static enableProject(uuid: string): Promise<AxiosResponse> {
    return http.post(`projects/${uuid}/enable`)
  }

  static isFreePlanAvailable(uuid: string): Promise<AxiosResponse> {
    return http.get(`projects/${uuid}/free-plan-available`).then((response: AxiosResponse) => response.data)
  }

  static projectMediaDownload(project_uuid: string, media_id: string): Promise<AxiosResponse> {
    return http
      .get(`projects/${project_uuid}/media-files/${media_id}/download`, {
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => response.data)
  }

  static getPaymentLink(uuid: string): Promise<AxiosResponse> {
    return http.get(`phases/${uuid}/payment-link`)
  }

  static sendVerificationCode(): Promise<AxiosResponse> {
    return http.post('verification/send-code')
  }
}
