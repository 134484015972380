import Currency from '@/shared/interfaces/models/currency'
import i18n from '@/i18n'

export interface CurrencyStateInterface {
  currency: Currency
}

export default (): CurrencyStateInterface => ({
  currency: {
    sign: '€',
    key: 'eur',
    text: String(i18n.t('Eur.')),
  },
})
