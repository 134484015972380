import { AuthStateInterface } from '@/shared/store/auth/auth.state'
import User from '@/shared/interfaces/models/user/user'

export enum AuthMutations {
  reset = 'AUTH/RESET',
  setUser = 'AUTH/SET_USER',
  updateExperienceLevel = 'AUTH/UPDATE_EXPERIENCE_LEVEL',
}

export default {
  [AuthMutations.setUser]: (state: AuthStateInterface, payload: User): void => {
    state.user = payload

    // @ts-ignore
    if (!state.user.details) state.user.details = {}
  },
  [AuthMutations.reset]: (state: AuthStateInterface): void => {
    state.user = null
  },
  [AuthMutations.updateExperienceLevel]: (state: AuthStateInterface): void => {
    if (state.user?.is_experience_level_set === false) {
      state.user.is_experience_level_set = true
    }
  },
}
