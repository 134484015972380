import { CurrencyStateInterface } from '@/shared/store/currency/currency.state'

export enum CurrencyMutations {
  updateCurrency = 'CURRENCY/UPDATE_CURRENCY',
}

export default {
  [CurrencyMutations.updateCurrency]: (state: CurrencyStateInterface, payload: any): void => {
    state.currency = payload
  },
}
